<template>
    <v-container id="profile" fluid v-if="user && isLoggedIn">
        <v-row class="main pa-0 ma-0">
            <v-col class="mx-auto my-0 pa-0" cols="12">
                <v-card class="ma-auto pa-4 elevation-0 transparent" width="fit-content" data-aos="slide-down">
                    <v-card-title class="ma-0 pa-2">
                        <v-avatar
                            class="ma-auto rounded-circle"
                            color="custom-accent"
                            size="88">
                            <span class="text-uppercase white--text text-h4">{{ user.firstname.charAt(0) }}{{ user.lastname.charAt(0)}}</span>
                        </v-avatar>
                    </v-card-title>
                    <v-card-title class="ma-0 pa-0 text-center">
                        <span class="ma-auto text-capitalize text--secondary">{{user.fullname}}</span>
                    </v-card-title>
                    <v-card-subtitle class="ma-0 pa-0 text-center">
                        <span class="ma-auto">{{user.email}}</span>
                    </v-card-subtitle>
                    <v-card-actions class="ma-0 pa-0">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="`tel:`+user.phone" @click="copy(user.phone)" class="success--text ma-auto" :title="user.phone" dark v-bind="attrs" v-on="on" icon>
                                <v-icon>
                                mdi-phone
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>{{user.phone}}</span>
                        </v-tooltip>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn :href="`mailto:`+user.email" @click="copy(user.email)" class="secondary--text ma-auto" :title="user.email" dark v-bind="attrs" v-on="on" icon>
                                <v-icon>
                                mdi-mail
                                </v-icon>
                            </v-btn>
                            </template>
                            <span>{{user.email}}</span>
                        </v-tooltip>
                    </v-card-actions>
                </v-card>
                <v-divider data-aos="zoom-in" data-aos-duration="1500"></v-divider>
                <v-row class="pa-0 my-4">
                    <v-col cols="12" sm="10" md="5" lg="4" class="mx-auto">
                        <contact-list @selectEmail="selectEmail" @setCategory="setCategory" @replyEmail="replyEmail" :contacts="contacts" :categories="categories" :selectedCategory="selectedCategory" :loading="contact_loading"></contact-list>
                    </v-col>
                    <v-col cols="12" sm="10" md="7" lg="8" class="mx-auto">
                        <contact-info v-if="activeAction==0" @deleteEmail="OpenDeleteEmail" @replyEmail="replyEmail" @forwardEmail="forwardEmail" @printEmail="printEmail" :selectedEmail="selectedEmail" :loading="contact_loading"></contact-info>
                        <contact-reply v-if="activeAction==1" @selectEmail="selectEmail" @sendEmail="sendEmail" :selectedEmail="selectedEmail" :loading="contact_loading"></contact-reply>
                        <contact-forward v-if="activeAction==2" @selectEmail="selectEmail" @sendEmail="sendEmail" :selectedEmail="selectedEmail" :loading="contact_loading"></contact-forward>
                        <contact-print v-if="activeAction==3" @selectEmail="selectEmail" :selectedEmail="selectedEmail" :loading="contact_loading"></contact-print>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- delete dialog -->
        <v-dialog v-model="contactdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="contactdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="contact_loading" depressed @click="DeleteContact()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

    </v-container>
</template>

<script>
import {mapGetters,mapState,mapActions} from "vuex";
import contactlist from "../../components/Inbox/ContactList.vue"
import contactinfo from "../../components/Inbox/ContactInfo.vue"
import contactreply from "../../components/Inbox/ContactReply.vue"
import contactforward from "../../components/Inbox/ContactForward.vue"
import contactprint from "../../components/Inbox/ContactPrint.vue"
export default {
    name: 'Inbox',
    data() {
        return {
            selectedEmail: null,
            activeAction: 0,
            contactdeleteoverlay: false,
            selectedCategory:{id:1,title:'inbox',icon:'mdi-inbox',disabled:false},
            categories:[{id:1,title:'inbox',icon:'mdi-inbox',disabled:false},{id:2,title:'sent',icon:'mdi-send',disabled:true}],
        }
    },
    components: {
        'contact-list':contactlist,
        'contact-info':contactinfo,
        'contact-reply':contactreply,
        'contact-forward':contactforward,
        'contact-print':contactprint,
    },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
        ...mapGetters('contact',{contacts:'getContacts'}),
        ...mapState("contact", {contact_loading: "loading"}),
    },
    created(){},
    watch:{},
    methods: {
        OpenDeleteEmail(item) {
            this.contactdeleteoverlay = true;
            this.selectedEmail = {...item};
        },
        setCategory(selectedCategory){
            this.selectedCategory={...selectedCategory};
        },
        selectEmail(selectedEmail){
            this.selectedEmail=selectedEmail;
            this.activeAction=0;
            if(this.selectedEmail && this.selectedEmail.status==0){
                this.selectedEmail.status=1;
                this.updateContact(this.selectedEmail);
            }
        },
        replyEmail(selectedEmail){
            this.selectedEmail=selectedEmail;
            this.activeAction=1;
            if(this.selectedEmail && this.selectedEmail.status==0){
                this.selectedEmail.status=1;
                this.updateContact(this.selectedEmail);
            }
        },
        forwardEmail(selectedEmail){
            this.selectedEmail=selectedEmail;
            this.activeAction=2;
            if(this.selectedEmail && this.selectedEmail.status==0){
                this.selectedEmail.status=1;
                this.updateContact(this.selectedEmail);
            }
        },
        printEmail(selectedEmail){
            this.selectedEmail=selectedEmail;
            this.activeAction=3;
            if(this.selectedEmail && this.selectedEmail.status==0){
                this.selectedEmail.status=1;
                this.updateContact(this.selectedEmail);
            }
        },
        sendEmail(reply){
            console.log(reply);
        },
        DeleteContact(){
            if(this.selectedEmail && this.user && this.user.permission.map(i=>i.name).includes('contacts.delete')){
                this.deleteContact(this.selectedEmail).then(() => {
                    this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                    this.contactdeleteoverlay = false;
                    this.selectedEmail = undefined;
                },() => {
                    this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
                });
            }
        },

        ...mapActions('contact',["updateContact","deleteContact"]),
    },
}
</script>
