<template> 
  <v-container class="ma-auto pa-0" fluid>
        <v-card class="mx-auto" outlined>
            <v-toolbar class="custom-light-gray" flat>
                <v-bottom-navigation class="elevation-0 transparent">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading" @click="replyEmail" :title="$t('reply')" text>
                                <span>{{$t('reply')}}</span>
                                <v-icon>mdi-reply</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('reply')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading" @click="forwardEmail" :title="$t('forward')" text>
                                <span>{{$t('forward')}}</span>
                                <v-icon>mdi-share</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('forward')}}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading" @click="printEmail" :title="$t('print')" text>
                                <span>{{$t('print')}}</span>
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('print')}}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="v-btn--active" :disabled="!selectedEmail || loading" @click="deleteEmail" :title="$t('delete')" text>
                                <span>{{$t('delete')}}</span>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t('delete')}}</span>
                    </v-tooltip>
                </v-bottom-navigation>
            </v-toolbar>
            <v-divider></v-divider>
            <transition name="fade" mode="out-in" v-if="loading">
                <v-sheet flat height="440">
                    <v-skeleton-loader type="card-heading,list-item-avatar-two-line,list-item-three-line"></v-skeleton-loader>
                </v-sheet>
            </transition>
            <transition name="fade" mode="out-in" v-if="!selectedEmail && !loading">
                <v-sheet height="440" class="text-center ma-0 pa-0">
                    <v-container class="pa-0 ma-0" fluid fill-height>
                        <v-row class="pa-0 ma-0" align="center" justify="center">
                            <v-col class="ma-0 pa-0"><span class="ma-0 pa-auto justify-center title font-weight-light text--secondary">{{$t("selectemail")}}</span></v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
            </transition>
            <transition name="fade" mode="out-in" v-if="selectedEmail && !loading">
                <v-sheet flat class="overflow-y-auto" height="440">
                    <v-list>
                        <v-list-item>
                            <v-list-item-content class="ma-0 pa-0">
                                <v-list-item-title>
                                    <h5 class="body-1">
                                        <v-badge right inline tile :content="selectedEmail.status==1?$t('seen'):selectedEmail.status==2?$t('replied'):''" :color="selectedEmail.status==1?'primary':selectedEmail.status==2?'secondary':''">
                                            {{selectedEmail.subject}}
                                        </v-badge>
                                    </h5>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="ma-0">
                            <v-list-item-avatar class="custom-light-gray" size="55">
                                <v-img :src="storage_url+default_path+'user.png'" alt="avatar"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="body-2">{{$t('from')}} 
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="primary--text text-decoration-underline text-capitalize" style="cursor:pointer" @click="copy(selectedEmail.email)" v-bind="attrs" v-on="on" :title="selectedEmail.email">{{selectedEmail.name}}</span>
                                        </template>
                                        <span class="text-capitalize">{{selectedEmail.email}}</span>
                                    </v-tooltip>
                                    {{$t('on')}}  <span class="subtitle-2" :title="selectedEmail.created_at | formatTimeDate">{{selectedEmail.created_at | formatTimeDate}}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="selectedEmail.email"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="ma-0 pa-0">
                                <v-list-item-title><p class="body-2" style="white-space: pre-line;" v-html="selectedEmail.content"></p></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </transition>
        </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default({
    
    props: ['selectedEmail','loading'],
    data() {
        return {
            storage_url: process.env.VUE_APP_STORAGEURL,
            default_path: "default_images/",
        }
    },
    components: { },
    computed: {
        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{},
    methods: {
        replyEmail(){
            if(this.selectedEmail)
                this.$emit('replyEmail',this.selectedEmail);
        },
        forwardEmail(){
            if(this.selectedEmail)
                this.$emit('forwardEmail',this.selectedEmail);
        },
        deleteEmail(){
            if(this.selectedEmail)
                this.$emit('deleteEmail',this.selectedEmail);
        },
        printEmail(){
            if(this.selectedEmail)
                this.$emit('printEmail',this.selectedEmail);
        },
        copy(item) {
            navigator.clipboard.writeText(item).then(()=>{
                this.$root.$emit('callNotify', { icon:'mdi-content-copy',  content:this.$t('copiedtoclipboard'), title:this.$t('success'), type:'success'});
            })
        },
    },
})
</script>
